<template>
  <div class="engineering">
    <!-- require('../../../../assets/image/img.jpg')-->
    <el-image
      class="image-engineering f f-j-c f-a-c"
      :src="projectInfo.vividPath"
      :fit="'cover'"
      @error="imageError"
    >
      <div slot="error" class="image-slot">
        <div class="error-text">形象工程图加载失败</div>
      </div>
    </el-image>
    <!-- 底部状态 -->
    <div v-if="rendering" class="footer-status p-a">
      <el-row class="p-15" :gutter="20">
        <el-col :span="4">
          <div class="sjzs warning-box">
            <div class="f f-j-c f-a-c">
              <dv-digital-flop :config="config1" class="digital-box" />
            </div>
            <div class="t-s">今日预报警</div>
          </div>
        </el-col>
        <el-col
          :span="4"
        ><div class="sjzs">
          <div class="f f-j-c f-a-c">
            <dv-digital-flop :config="config2" class="digital-box" />
          </div>
          <div class="t-s">总工期</div>
        </div>
        </el-col>
        <el-col
          :span="4"
        ><div class="sjzs">
          <div class="f f-j-c f-a-c">
            <dv-digital-flop :config="config3" class="digital-box" />
          </div>
          <div class="t-s">安全生产</div>
        </div></el-col>
        <el-col
          :span="4"
        ><div class="sjzs">
          <div class="f f-j-c f-a-c">
            <dv-digital-flop :config="config4" class="digital-box" />
          </div>
          <div class="t-s">在场人数</div>
        </div></el-col>
        <el-col
          :span="4"
        ><div class="sjzs">
          <div class="f f-j-c f-a-c">
            <dv-digital-flop :config="config5" class="digital-box" />
          </div>
          <div class="t-s">在场设备</div>
        </div></el-col>
        <el-col
          :span="4"
        ><div class="sjzs">
          <div class="f f-j-c f-a-c">
            <dv-digital-flop :config="config6" class="digital-box" />
          </div>
          <div class="t-s">在线设备</div>
        </div></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('project', {
      cTData: (state) => state.cTData,
      projectInfo: (state) => state.projectInfo
    })
  },
  data() {
    return {
      rendering: false,
      config1: {
        number: [0],
        content: '{nt} 次',
        style: {
          fontSize: 28,
          fill: '#fad400'
        }
      },
      config2: {
        number: [0],
        content: '{nt} 天',
        style: {
          fontSize: 28,
          fill: '#00ffff'
        }
      },
      config3: {
        number: [0],
        content: '{nt} 天',
        style: {
          fontSize: 28,
          fill: '#00ffff'
        }
      },
      config4: {
        number: [0],
        content: '{nt} 人',
        style: {
          fontSize: 28,
          fill: '#00ffff'
        }
      },
      config5: {
        number: [0],
        content: '{nt} 台',
        style: {
          fontSize: 28,
          fill: '#00ffff'
        }
      },
      config6: {
        number: [0],
        content: '{nt} 台',
        style: {
          fontSize: 28,
          fill: '#00ffff'
        }
      }
    }
  },
  watch: {
    cTData: {
      handler(nval, oval) {
        if (Object.keys(nval).length === 0) return
        setTimeout(() => {
          this.rendering = true
          this.$nextTick(() => {
            setTimeout(() => {
              this.config1.number = [nval.earlyWarningCount]
              this.config1 = { ...this.config1 }
              this.config2.number = [nval.totalDays]
              this.config2 = { ...this.config2 }
              this.config3.number = [nval.safeDays]
              this.config3 = { ...this.config3 }
              this.config4.number = [nval.doingPeopleCount]
              this.config4 = { ...this.config4 }
              this.config5.number = [nval.totalDeviceCount]
              this.config5 = { ...this.config5 }
              this.config6.number = [nval.onlineDeviceCount]
              this.config6 = { ...this.config6 }
            }, 100)
          })
        }, 100)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * 图片加载失败回调
     */
    imageError(e) {}
  }
}
</script>

<style lang="scss" scoped>
.image-engineering {
  width: calc(100% - 6px);
  height: 525px;
  margin-left: 3px;
  margin-top: 2px;
  border-radius: 4px;
  .error-text {
    color: rgba(0, 255, 255, 0.6);
    font-size: 25px;
    letter-spacing: 1px;
  }
}
.footer-status {
  height: 119px;
  bottom: 5px;
  left: 3px;
  right: 3px;
  background-color: rgba(1, 21, 46, 0.8) !important;
}
.warning-box {
  border: 1px solid rgba(250, 212, 0, 0.9) !important;
  padding: 5px 10px 15px 10px !important;
  text-align: center !important;
  box-shadow: 0px 0px 15px rgba(250, 212, 0, 0.5) inset !important;
  border-radius: 4px !important;
  background-image: linear-gradient(
    rgba(250, 212, 0, 0.2) 1%,
    rgba(250, 212, 0, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  ) !important;
}

.sjzs {
  border: 1px solid rgba(0, 255, 255, 0.9);
  padding: 5px 10px 15px 10px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
  border-radius: 4px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2) 1%,
    rgba(0, 255, 255, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  );
}
.t-s {
  font-size: 16px;
  font-family: "Digital-7";
  color: #fff;
}
.digital-box {
  height: 46px;
}
</style>
