<template>
  <div class="content-box-item p-b-0">
    <el-row class="row clearfix">
      <el-col :span="13">
        <div class="gcjj">
          <p class="text-ellipsis t-s">
            承建单位：{{ projectInfo.cjUnitName || "-" }}
          </p>
          <p class="text-ellipsis t-s">
            建设单位：{{ projectInfo.jsUnitName || "-" }}
          </p>
          <p class="text-ellipsis t-s">
            监理单位：{{ projectInfo.jlUnitName || "-" }}
          </p>
        </div>
      </el-col>
      <el-col :span="10" :push="1">
        <div class="gcjj">
          <p class="text-ellipsis t-s">
            总造价：{{ projectInfo.invest | handleInvest }} 亿
          </p>
          <p class="text-ellipsis t-s">
            开工日期：{{ projectInfo.startDate | parseTime("{y}-{m}-{d}") }}
          </p>
          <p class="text-ellipsis t-s">
            建筑面积：{{ projectInfo.buildingArea | handleInvest }} 平方米
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { thousands } from "@/utils/index";
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  computed: {
    ...mapState("project", {
      projectInfo: (state) => state.projectInfo,
    }),
  },
  filters: {
    handleInvest(value) {
      return thousands(value);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang='scss' scoped>
.gcjj {
  font-size: 12px;
  color: #fff;
  p {
    margin-bottom: 11px;
  }
}
.t-s {
  font-size: 16px;
  font-family: "Digital-7";
  color: #fff;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
</style>
