<template>
  <div class="aerial-photo">
    <div
      v-show="projectInfo.aerialPanorama"
      id="photosphere"
      class="photosphere-box"
    ></div>
    <div
      v-show="!projectInfo.aerialPanorama"
      class="not-text w-100 t-a-c c-p"
      @click="openMIS"
    >
      请前往项目中心维护...
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Viewer } from "photo-sphere-viewer";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
export default {
  computed: {
    ...mapState("project", {
      projectInfo: (state) => state.projectInfo,
    }),
  },
  data() {
    return {
      fullscreenStatus: false,
      PSV: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (!this.projectInfo.aerialPanorama) return;
        this.initPhotoSphere();
      }, 100);
    });
  },
  methods: {
    /**
     * 初始化全景图
     */
    initPhotoSphere() {
      const _this = this;

      _this.PSV = new Viewer({
        container: document.getElementById("photosphere"), //全景容器
        // panorama: 'http://tassedecafe.org/wp-content/uploads/2013/01/parc-saint-pierre-amiens.jpg', //全景图片路径
        // panorama: 'http://img.dev.axlcloud.cn/labor/images/project/202106105459465.png', //全景图片路径
        // panorama: require('../../../../assets/image/zhizhuxia.jpg'), //全景图片路径
        panorama: this.projectInfo.aerialPanorama, //全景图片路径
        caption: "航拍全景图", //导航栏中显示的文本
        //全景容器尺寸
        size: {
          width: "100%",
          height: "100%",
        },
        maxFov: 100, // 最大缩放值
        minFov: 10, // 最小缩放值
        defaultZoomLvl: 20, // 初始缩放值，在1-179之间
        fisheye: false, // 启用鱼眼效果
        // defaultLong: 0.0800613513013615,//初始经度，介于 0 和 2π 之间。
        // defaultLat: 1.4441088145446443,//初始纬度，介于 -π/2 和 π/2 之间。
        autorotateDelay: true, // 是否自动旋转
        autorotateSpeed: 0.12943951023931953, // 自动旋转速度(以毫秒为单位)
        loadingImg: null, // loading图路径
        loadingTxt: "航拍图加载中...", // loading文本
        mousewheel: true, // 鼠标滚轮缩放
        mousemove: true, // 使用鼠标光标或手指在触摸屏上滑动启用全景旋转
        captureCursor: false, // 只需将光标移动到视图上方而不是单击+移动即可旋转全景图
        touchmoveTwoFingers: false, // 需要两个手指来旋转全景
        // sphereCorrection: {pan: 30.01, tilt: 0, roll: 0}, // 校正 低 倾斜 卷(球体旋转角度，以弧度为单位。)
        moveSpeed: 0.8, // 鼠标拖拽的速度
        // time_anim: false,//全景图在多少毫秒后会自动进行动画
        // navbar: false, //导航栏配置
        navbar: [
          "autorotate",
          "download",
          "zoom",
          // "move",
          "caption",
          "fullscreen", // 全屏
          // 自定义按钮
          // {
          //   id: "my-button",
          //   content: "Custom",
          //   title: "Hello world",
          //   className: "custom-button",
          //   onClick: () => {
          //     alert("Hello from custom button");
          //   },
          // },
        ],
      });

      // console.log("this.PSV", this.PSV);
      // 全屏回调事件
      this.PSV.on("fullscreen-updated", (po) => {
        // console.log("全屏更新回调", po);
        if (!_this.fullscreenStatus) {
          _this.handleCanvas("1920px", "1080px");
          _this.fullscreenStatus = true;
        } else {
          _this.handleCanvas("924px", "525px");
          _this.fullscreenStatus = false;
        }
      });
    },
    /**
     * 重置canvas宽高
     */
    handleCanvas(w, h) {
      const rq = document.getElementsByClassName("psv-canvas-container")[0];
      const canvasEl = rq.getElementsByTagName("canvas")[0];
      canvasEl.style.width = w;
      canvasEl.style.height = h;
    },
    /**
     * 打开MIS系统
     */
    openMIS() {
      window.open("http://admin.site.axlyun.com/");
    },
  },
};
</script>

<style lang="scss" scoped>
.aerial-photo {
  width: calc(100% - 6px);
  height: 525px;
  margin-left: 3px;
  margin-top: 2px;
  border-radius: 4px;
  .photosphere-box {
    width: 100%;
    height: 100%;
  }
  .not-text {
    margin-top: 25%;
    font-size: 25px;
    color: #ffffffc7;
    &:hover {
      color: rgba(0, 255, 255, 1) !important;
    }
  }
}
</style>
