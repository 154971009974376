<template>
  <div class="content-box-item p-f">
    <el-row class="el-row">
      <el-col :span="12">
        <div class="f f-c f-a-c">
          <div class="m-t-10 c-fff">今日进场管理人员</div>
          <number-scroll
            :num="todayCount.manager"
            :max-col="
              todayCount.manager && todayCount.manager.length > 4
                ? todayCount.manager.length
                : 4
            "
          />
        </div>
      </el-col>
      <el-col :span="12">
        <div class="f f-c f-a-c">
          <div class="m-t-10 c-fff">今日进场劳务人员</div>
          <number-scroll
            :num="todayCount.worker"
            :max-col="
              todayCount.worker && todayCount.worker.length > 4
                ? todayCount.worker.length
                : 4
            "
          />
        </div>
      </el-col>
    </el-row>
    <div class="box-item">
      <template v-if="laborData && laborData.length > 0">
        <line-chart
          id="labor"
          :height="155"
          :margin-top="0"
          :val-options="laborData"
          title=""
          y-axis-title="近七天劳务出工情况"
          serie-name="小时"
        />
      </template>
      <template v-else>
        <div class="stat-charts-context empty mt-50">暂无近7天劳务出工情况</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NumberScroll from "@/components/number-scroll";
import lineChart from "@/components/chart/lineChart.vue";
export default {
  name: "",
  components: {
    NumberScroll,
    lineChart,
  },
  computed: {
    ...mapState("project", {
      todayCount: (state) => state.todayCount,
      sevenDaysCount: (state) => state.sevenDaysCount,
    }),
  },
  data() {
    return {
      laborData: [],
    };
  },
  watch: {
    sevenDaysCount: {
      handler(nval, oval) {
        this.laborData.push(
          {
            name: "管理人员",
            data: nval.manager,
            isAllData: false,
          },
          {
            name: "劳务人员",
            data: nval.worker,
            isAllData: false,
          }
        );
      },
      deep: true,
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.el-row {
  width: 421px;
}
.m-t-10 {
  margin-bottom: 10px;
  font-size: 18px;
}
.box-item {
  margin-top: 15px;
  height: 155px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
}
</style>
