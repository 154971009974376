<template>
  <div class="online-super">
    <monitor :box-height="511" :sign="true" :item="projectInfo.videoDevice" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import monitor from '@/components/monitor'
export default {
  components: {
    monitor
  },
  computed: {
    ...mapState('project', {
      projectInfo: (state) => state.projectInfo
    })
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.online-super {
  width: calc(100% - 6px);
  height: 525px;
  margin-left: 3px;
  margin-top: 2px;
  border-radius: 4px;
}
</style>
