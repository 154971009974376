<template>
  <div class="rollNum-main">
    <ul>
      <!-- 需要显示6列 -->
      <li v-for="i in maxCol" ref="li" :key="i">
        <!-- 每列中的10行数字 -->
        <span v-for="num in 10" :key="num">{{ num - 1 }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RollNum',
  components: {},
  props: {
    maxCol: {
      type: Number,
      default: 4
    },
    num: {
      type: String,
      default: '0'
    }
  },
  watch: {
    num: {
      handler(nval, oval) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.toOrderNum(nval, this.$refs.li)
          }, 500)
        })
      },
      deep: true
    }
  },
  methods: {
    // 处理数字
    toOrderNum(num, el) {
      num = num.toString()
      // 把数变成字符串
      if (num.length < this.maxCol) {
        num = '0' + num // 如未满八位数，添加"0"补位
        this.toOrderNum(num, el) // 递归添加"0"补位
      } else if (num.length === this.maxCol) {
        const numArr = num.split('') // 将其便变成数据，渲染至滚动数组
        el.forEach((item, index) => {
          const ty = numArr[index]
          item.style.transform = `translate(0%, -${ty * 100}%)`
        })
      } else {
        this.$message.warning('数字过大~')
      }
    }
  }
}
</script>

<style lang="scss">
.rollNum-main {
  ul {
    display: flex;
    justify-content: center;
    overflow: hidden;
    li {
      width: 33px;
      height: 50px;
      border-radius: 4px;
      transition: transform 1s ease-in-out;
      list-style-type: none;
      margin: 0 5px;
      span {
        font-weight: bold;
        background: rgba(0, 255, 255, 0.2);
        background-image: linear-gradient(
          rgba(0, 255, 255, 0.8) 1%,
          rgba(0, 255, 255, 0.2) 50%,
          rgba(0, 255, 255, 0) 50%
        );
        //border: 1px solid #00ffff;
        color: #fff;
        font-family: "Digital-7";
        font-size: 24px;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
