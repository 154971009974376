<template>
  <div class="project-content">
    <!-- 头部 -->
    <project-head />
    <!-- 内容区域 -->
    <div class="project-main mt-20 p-l-10 p-r-10">
      <el-row>
        <!-- 左边 -->
        <el-col :span="6" class="p-l-10 p-r-10">
          <!-- 左上 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-left-top-box animated bounceIn"
          >
            <card-title>项目概括</card-title>
            <!-- 左上内容 -->
            <project-left-top />
          </dv-border-box-7>
          <!-- 左中 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-left-cente-box mt-20 animated bounceIn"
          >
            <card-title @click="openPage('personnel')" is-more>人员管理</card-title>
            <!-- 左中内容 -->
            <project-left-center />
          </dv-border-box-7>
          <!-- 左下 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-left-bottom-box mt-20 animated bounceIn"
          >
            <!-- 左下内容 -->
            <project-left-bottom />
          </dv-border-box-7>
        </el-col>

        <!-- 中间 -->
        <el-col :span="12" class="p-l-10 p-r-10">
          <!-- 中间-上 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-center-top-box animated bounceIn"
          >
            <!-- 中间-上内容 -->
            <project-center-top />
          </dv-border-box-7>
          <div class="c-f-main f">
            <!-- 中间-下 -->
            <dv-border-box-7
              :color="['#10568e', 'rgb(124, 231, 253,1)']"
              class="project-center-bottom-box mt-20 animated bounceIn"
            >
              <card-title @click="openPage('envSensors')" is-more>环境监测</card-title>
              <!-- 中间-下-左内容 -->
              <project-center-bottom-left />
            </dv-border-box-7>
            <!-- 中间-下 -->
            <dv-border-box-7
              :color="['#10568e', 'rgb(124, 231, 253,1)']"
              class="project-center-bottom-box mt-20 ml-20 animated bounceIn"
            >
              <card-title @click="openPage('vehicle')" is-more>车辆管理</card-title>
              <!-- 中间-下-右内容 -->
              <project-center-bottom-right />
            </dv-border-box-7>
          </div>
        </el-col>

        <!-- 右边 -->
        <el-col :span="6" class="p-l-10 p-r-10">
          <!-- 右上 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-right-top-box animated bounceIn"
          >
            <div class="child-page-title">
              <div>实时播报</div>
            </div>
            <!-- 右上内容 -->
            <project-right-top />
          </dv-border-box-7>
          <!-- 右中 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-right-center-box mt-20 animated bounceIn"
          >
            <!-- 右中内容 -->
            <project-right-center />
          </dv-border-box-7>
          <!-- 右下 -->
          <dv-border-box-7
            :color="['#10568e', 'rgb(124, 231, 253,1)']"
            class="project-right-bottom-box mt-20 animated bounceIn"
          >
            <card-title @click="openPage('lawArticles')" is-more>公告看板</card-title>
            <!-- 右下内容 -->
            <project-right-bottom />
          </dv-border-box-7>
        </el-col>
      </el-row>
    </div>

    <loading v-if="loading" />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import projectHead from "./components/project-head";
import cardTitle from "@/components/card-title";
import projectLeftTop from "./components/project-left-top";
import projectLeftCenter from "./components/project-left-center";
import projectLeftBottom from "./components/project-left-bottom";
import projectCenterTop from "./components/project-center-top";
import projectCenterBottomLeft from "./components/project-center-bottom-left";
import projectCenterBottomRight from "./components/project-center-bottom-right";
import projectRightTop from "./components/project-right-top";
import projectRightCenter from "./components/project-right-center";
import projectRightBottom from "./components/project-right-bottom";
export default {
  name: "Project",
  components: {
    Loading,
    projectHead,
    cardTitle,
    projectLeftTop,
    projectLeftCenter,
    projectLeftBottom,
    projectCenterTop,
    projectCenterBottomLeft,
    projectCenterBottomRight,
    projectRightTop,
    projectRightCenter,
    projectRightBottom,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  created() {
    this.loading = true;
    const p1 = this.$store.dispatch("project/getHome");
    const p2 = this.$store.dispatch("project/getWeatherDatas");
    Promise.all([p1, p2]).then((res) => {
      this.loading = false;
    });

    // const p1 = this.$store.dispatch("project/getTodayCountDatas");
    // const p2 = this.$store.dispatch("project/getPastCarCountDatas");
    // const p3 = this.$store.dispatch("project/getDeviceStatusDatas", "TOWE"); // 获取塔吊设备统计数据
    // const p4 = this.$store.dispatch("project/getAllTowerDatas"); // 获取塔吊实时数据
    // const p5 = this.$store.dispatch("project/getDeviceStatusDatas", "LIFTER"); // 获取人货梯设备统计数据
    // const p6 = this.$store.dispatch("project/getAllLifterDatas"); // 获取人货梯实时数据
    // const p7 = this.$store.dispatch("project/getAllEnvSensorDatas"); // 获取环境监测实时数据
    // const p8 = this.$store.dispatch("project/getSevenDaysCountDatas");
    // const p9 = this.$store.dispatch("project/getVehicleLineStatDatas", 7);
    // const p10 = this.$store.dispatch("project/getLatestDatas");
    // const p11 = this.$store.dispatch("project/getRateStatDatas", "QUALITY"); // 质量统计率
    // const p12 = this.$store.dispatch("project/getLatestAlarmLogDatas");
    // const p13 = this.$store.dispatch("project/getLatestVehicleDatas");
    // const p14 = this.$store.dispatch("project/getTrendStatLineDatas", "SAFETY"); // 安全趋势图
    // const p15 = this.$store.dispatch("project/getRateStatDatas", "SAFETY"); // 安全统计率
    // const p16 = this.$store.dispatch(
    //   "project/getTrendStatLineDatas",
    //   "QUALITY"
    // ); // 质量趋势图
    // const p17 = this.$store.dispatch("project/getArticleListDatas", 20); // 安全趋势图
    // const p18 = this.$store.dispatch("project/getPrjComplexStatDatas");
    // const p19 = this.$store.dispatch("project/getProjectDatas");
    // const p20 = this.$store.dispatch("project/getWeatherDatas");
    // Promise.all([
    //   p1,
    //   p2,
    //   p3,
    //   p4,
    //   p5,
    //   p6,
    //   p7,
    //   p8,
    //   p9,
    //   p10,
    //   p11,
    //   p12,
    //   p13,
    //   p14,
    //   p15,
    //   p16,
    //   p17,
    //   p18,
    //   p19,
    //   p20,
    // ]).then((res) => {
    //   this.loading = false;
    // });
  },
  mounted() {},
  methods: {
    /**
     * 跳转页面
     */
    openPage(path) {
      this.$router.push(`/${path}`);
    },
  },
};
</script>

<style lang='scss' scoped>
.project-content {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw);
  height: calc(100vh);
  background: url("../../assets/image/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  .project-left-top-box {
    width: 100%;
    height: 146px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-left-cente-box {
    width: 100%;
    height: 313px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-left-bottom-box {
    width: 100%;
    height: 432px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-center-top-box {
    width: 100%;
    height: 530px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .c-f-main {
    width: 100%;
  }
  .project-center-bottom-box {
    width: 100%;
    // height: 379px;
    height: 383px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-right-top-box {
    width: 100%;
    height: 348px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-right-center-box {
    width: 100%;
    height: 331px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
  .project-right-bottom-box {
    width: 100%;
    height: 212px;
    border: none;
    background: rgba(100, 116, 211, 0.04);
  }
}
</style>
