<template>
  <div>
    <el-carousel
      :height="'431px'"
      :indicator-position="'none'"
      :arrow="'hover'"
      :autoplay="true"
      :interval="30000"
      :loop="true"
      @change="carouselChange"
    >
      <!-- 塔吊监测 -->
      <el-carousel-item>
        <card-title @click="openPage('tower')" is-more>塔吊监测</card-title>
        <div v-if="!carouselShow" class="content-box-item">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="towerConfig1" class="bj" />
                <div class="font14 c-fff">在线</div>
              </div></el-col>
            <el-col :span="6">
              <div class="wary-zg-box">
                <dv-digital-flop :config="towerConfig2" class="bj" />
                <div class="font14">离线</div>
              </div></el-col>
            <el-col :span="6">
              <div class="warning-zg-box">
                <dv-digital-flop :config="towerConfig3" class="bj" />
                <div class="font14">预警</div>
              </div></el-col>
            <el-col :span="6">
              <div class="danger-zg-box">
                <dv-digital-flop :config="towerConfig4" class="bj" />
                <div class="font14">报警</div>
              </div></el-col>
          </el-row>
          <div class="f-box mt-20">
            <el-carousel
              :height="'238px'"
              :indicator-position="'none'"
              :arrow="'never'"
              :autoplay="true"
              :interval="10000"
              :loop="true"
            >
              <template v-if="towerAllData && towerAllData.length > 0">
                <template v-for="(t, ti) in towerAllData">
                  <el-carousel-item :key="ti">
                    <el-row>
                      <el-col :span="8">
                        <div class="td-icon ml-10 mr-10 f f-c f-a-c">
                          <el-image
                            class="c-p"
                            :src="require('../../../assets/image/td-icon.png')"
                            :fit="'contain'"
                          />
                          <p class="box-w text-s text-ellipsis t-a-c">
                            {{ t.name }}
                          </p>
                        </div></el-col>
                      <el-col :span="8">
                        <div class="tdxx ml-10 mr-10">
                          <p class="text-s">
                            状态<span>{{ t.status | handelStatus }}</span>
                          </p>
                          <p class="text-s">
                            载重<span>{{ t.ratedWeight }}吨</span>
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="tdxx ml-10 mr-10">
                          <p class="text-s">
                            司机<span>{{ t.operName || "-" }}</span>
                          </p>
                          <p class="text-s">
                            风级<span>{{ t.windLevel }}级</span>
                          </p>
                        </div></el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ t.weight }}吨</span>
                        <div class="text-s">吊重</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ t.rRange }}米</span>
                        <div class="text-s">幅度</div>
                      </div></el-col>
                      <el-col :span="8">
                        <div class="tdxx-box ml-10 mr-10">
                          <span>{{ t.height }}米</span>
                          <div class="text-s">高度</div>
                        </div></el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ t.moment }}%</span>
                        <div class="text-s">力矩</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ t.windSpeed }}米/秒</span>
                        <div class="text-s">风速</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ t.obliguity }}度</span>
                        <div class="text-s">倾角</div>
                      </div></el-col>
                    </el-row>
                  </el-carousel-item>
                </template>
              </template>
              <template v-else>
                <div class="not-have-warning f f-j-c f-a-c">
                  暂无塔吊相关信息!
                </div>
              </template>
            </el-carousel>
          </div>
        </div>
      </el-carousel-item>

      <!-- 人货梯监测 -->
      <el-carousel-item>
        <card-title @click="openPage('lifter')" is-more>人货梯监测</card-title>
        <div v-if="carouselShow" class="content-box-item">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="lifterConfig1" class="bj" />
                <div class="font14 c-fff">在线</div>
              </div></el-col>
            <el-col :span="6">
              <div class="wary-zg-box">
                <dv-digital-flop :config="lifterConfig2" class="bj" />
                <div class="font14">离线</div>
              </div></el-col>
            <el-col :span="6">
              <div class="warning-zg-box">
                <dv-digital-flop :config="lifterConfig3" class="bj" />
                <div class="font14">预警</div>
              </div></el-col>
            <el-col :span="6">
              <div class="danger-zg-box">
                <dv-digital-flop :config="lifterConfig4" class="bj" />
                <div class="font14">报警</div>
              </div></el-col>
          </el-row>
          <div class="f-box mt-20">
            <el-carousel
              :height="'238px'"
              :indicator-position="'none'"
              :arrow="'never'"
              :autoplay="true"
              :interval="10000"
              :loop="true"
            >
              <template v-if="lifterAllData && lifterAllData.length > 0">
                <template v-for="(l, li) in lifterAllData">
                  <el-carousel-item :key="li">
                    <el-row>
                      <el-col :span="8">
                        <div class="td-icon ml-10 mr-10 f f-c f-a-c">
                          <el-image
                            class="c-p rht-image"
                            :src="require('../../../assets/image/rht.png')"
                            :fit="'contain'"
                          />
                          <p class="box-w text-s text-ellipsis t-a-c">
                            {{ l.name || "-" }}
                          </p>
                        </div></el-col>
                      <el-col :span="8">
                        <div class="tdxx ml-10 mr-10">
                          <p class="text-s">
                            状态<span>{{ l.status | handelStatus }}</span>
                          </p>
                          <p class="text-s">
                            人数<span>{{ l.peopleCount }}人</span>
                          </p>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="tdxx ml-10 mr-10">
                          <p class="text-s">
                            司机<span>{{ l.operName || "-" }}</span>
                          </p>
                          <p class="text-s">
                            风级<span>{{ l.windLevel }}级</span>
                          </p>
                        </div></el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ l.weight }}吨</span>
                        <div class="text-s">载重</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ l.speed }}米/秒</span>
                        <div class="text-s">速度</div>
                      </div></el-col>
                      <el-col :span="8">
                        <div class="tdxx-box ml-10 mr-10">
                          <span>{{ l.height }}米</span>
                          <div class="text-s">高度</div>
                        </div></el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ l.floor }}层</span>
                        <div class="text-s">楼层</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ l.obliguityX }}度</span>
                        <div class="text-s">倾角</div>
                      </div></el-col>
                      <el-col
                        :span="8"
                      ><div class="tdxx-box ml-10 mr-10">
                        <span>{{ l.windSpeed }}米/秒</span>
                        <div class="text-s">风速</div>
                      </div></el-col>
                    </el-row>
                  </el-carousel-item>
                </template>
              </template>
              <template v-else>
                <div class="not-have-warning f f-j-c f-a-c">
                  暂无人货梯相关信息!
                </div>
              </template>
            </el-carousel>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cardTitle from "@/components/card-title";
export default {
  name: '',
  components: { cardTitle },
  computed: {
    ...mapState('project', {
      towerDeviceStatus: (state) => state.towerDeviceStatus,
      towerAllData: (state) => state.towerAllData,
      lifterData: (state) => state.lifterData,
      lifterAllData: (state) => state.lifterAllData
    })
  },
  filters: {
    handelStatus(value) {
      switch (value) {
        case 0:
          return '离线'
          break
        case 1:
          return '在线'
          break
        case 3:
          return '报警'
          break
        case 9:
          return '退场'
          break
      }
    }
  },
  data() {
    return {
      carouselShow: true,
      // 塔吊统计数据
      towerConfig1: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      towerConfig2: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#999'
        }
      },
      towerConfig3: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fad400'
        }
      },
      towerConfig4: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#ff0000'
        }
      },
      // 人货梯统计数据
      lifterConfig1: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      lifterConfig2: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#999'
        }
      },
      lifterConfig3: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fad400'
        }
      },
      lifterConfig4: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#ff0000'
        }
      }
    }
  },
  watch: {
    // 塔吊统计数据
    towerDeviceStatus: {
      handler(nval, oval) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.carouselShow = false
            setTimeout(() => {
              this.towerConfig1.number = [nval.online]
              this.towerConfig1 = { ...this.towerConfig1 }
              this.towerConfig2.number = [nval.offline]
              this.towerConfig2 = { ...this.towerConfig2 }
              this.towerConfig3.number = [nval.earlyWarning]
              this.towerConfig3 = { ...this.towerConfig3 }
              this.towerConfig4.number = [nval.alarming]
              this.towerConfig4 = { ...this.towerConfig4 }
            }, 100)
          }, 100)
        })
      },
      deep: true
    },
    // 人货梯统计数据
    lifterData: {
      handler(nval, oval) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.carouselShow = false
            setTimeout(() => {
              this.lifterConfig1.number = [nval.online]
              this.lifterConfig1 = { ...this.lifterConfig1 }
              this.lifterConfig2.number = [nval.offline]
              this.lifterConfig2 = { ...this.lifterConfig2 }
              this.lifterConfig3.number = [nval.earlyWarning]
              this.lifterConfig3 = { ...this.lifterConfig3 }
              this.lifterConfig4.number = [nval.alarming]
              this.lifterConfig4 = { ...this.lifterConfig4 }
            }, 100)
          }, 100)
        })
      },
      deep: true
    }
  },
  created() {},
  methods: {
    carouselChange(index) {
      this.carouselShow = !this.carouselShow
      // 塔吊
      this.towerConfig1.number = [0]
      this.towerConfig1 = { ...this.towerConfig1 }
      this.towerConfig2.number = [0]
      this.towerConfig2 = { ...this.towerConfig2 }
      this.towerConfig3.number = [0]
      this.towerConfig3 = { ...this.towerConfig3 }
      this.towerConfig4.number = [0]
      this.towerConfig4 = { ...this.towerConfig4 }
      // 人货梯
      this.lifterConfig1.number = [0]
      this.lifterConfig1 = { ...this.lifterConfig1 }
      this.lifterConfig2.number = [0]
      this.lifterConfig2 = { ...this.lifterConfig2 }
      this.lifterConfig3.number = [0]
      this.lifterConfig3 = { ...this.lifterConfig3 }
      this.lifterConfig4.number = [0]
      this.lifterConfig4 = { ...this.lifterConfig4 }
      this.$nextTick(() => {
        setTimeout(() => {
          // 塔吊
          const towerItem = this.towerDeviceStatus
          this.towerConfig1.number = [towerItem.online]
          this.towerConfig1 = { ...this.towerConfig1 }
          this.towerConfig2.number = [towerItem.offline]
          this.towerConfig2 = { ...this.towerConfig2 }
          this.towerConfig3.number = [towerItem.earlyWarning]
          this.towerConfig3 = { ...this.towerConfig3 }
          this.towerConfig4.number = [towerItem.alarming]
          this.towerConfig4 = { ...this.towerConfig4 }
          // 人货梯
          const lifterItem = this.lifterData
          this.lifterConfig1.number = [lifterItem.online]
          this.lifterConfig1 = { ...this.lifterConfig1 }
          this.lifterConfig2.number = [lifterItem.offline]
          this.lifterConfig2 = { ...this.lifterConfig2 }
          this.lifterConfig3.number = [lifterItem.earlyWarning]
          this.lifterConfig3 = { ...this.lifterConfig3 }
          this.lifterConfig4.number = [lifterItem.alarming]
          this.lifterConfig4 = { ...this.lifterConfig4 }
        }, 100)
      })
    },
    /**
     * 跳转页面
     */
    openPage(path) {
      this.$router.push(`/${path}`)
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-carousel__arrow {
  //  background: rgba(153,153,153, 0.2)  !important;
  background: rgba(0, 255, 255, 0.4) !important;
}
.zg-box {
  border-radius: 50%;
  border: 3px solid #00ffff;
  box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
  background: rgba(0, 255, 255, 0.1);
  width: 84px;
  height: 84px;
  text-align: center;
  margin: 0 auto;
  font-family: "Digital-7";
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2) 1%,
    rgba(0, 255, 255, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  );
}
.wary-zg-box {
  border-radius: 50%;
  border: 3px solid #999;
  width: 84px;
  height: 84px;
  text-align: center;
  margin: 0 auto;
  color: #999;
  font-family: "Digital-7";
  box-shadow: 0px 0px 15px rgba(153, 153, 153, 0.5) inset;
  background-image: linear-gradient(
    rgba(153, 153, 153, 0.3) 1%,
    rgba(153, 153, 153, 0.1) 60%,
    rgba(153, 153, 153, 0) 60%
  );
}
.warning-zg-box {
  border-radius: 50%;
  border: 3px solid #fad400;
  width: 84px;
  height: 84px;
  text-align: center;
  margin: 0 auto;
  font-family: "Digital-7";
  color: #fad400;
  box-shadow: 0px 0px 15px rgba(250, 212, 0, 0.5) inset;
  background-image: linear-gradient(
    rgba(250, 212, 0, 0.3) 1%,
    rgba(250, 212, 0, 0.1) 60%,
    rgba(250, 212, 0, 0) 60%
  );
}
.danger-zg-box {
  border-radius: 50%;
  border: 3px solid #ff0000;
  width: 84px;
  height: 84px;
  text-align: center;
  margin: 0 auto;
  font-family: "Digital-7";
  color: #ff0000;
  box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5) inset;
  background-image: linear-gradient(
    rgba(255, 0, 0, 0.4) 1%,
    rgba(255, 0, 0, 0.2) 60%,
    rgba(255, 0, 0, 0) 60%
  );
}
.f-box {
  height: 238px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
  padding: 15px 10px 5px 10px;
}
.box-w {
  width: 100px;
}
.td-icon {
  height: 64px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2),
    rgba(0, 255, 255, 0.1)
  );
  border-radius: 4px;
  padding: 5px;
}
.rht-image {
  width: 36px !important;
  height: 36px !important;
  padding: 5px 0;
}
.tdxx-box {
  border-radius: 4px;
  background: rgba(0, 255, 255, 0.1);
  text-align: center;
  padding: 0;
  margin: 8px 0;
  color: #eee;
}
.tdxx-box span {
  display: block;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.4),
    rgba(0, 255, 255, 0.1)
  );
  padding: 5px 0;
  border-radius: 4px;
  color: #00ffff;
  font-size: 16px;
}

.tdxx-box > div {
  padding: 5px 0;
}

.tdxx p {
  height: 22px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2),
    rgba(0, 255, 255, 0.1)
  );
  text-align: left;
  padding: 5px;
}
.text-s {
  font-family: "微软雅黑";
  font-size: 15px;
  color: #fff;
}
.tdxx span {
  margin: 0 5px;
  border-radius: 4px;
  font-size: 15px;
  color: #00ffff;
}
.bj {
  height: 45px;
  margin-top: 10px;
}
.font30 {
  font-size: 30px;
}
.font14 {
  font-size: 14px;
}
.not-have-warning {
  height: 150px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 255, 255, 0.6);
}
</style>
