<template>
  <div class="content-box-item">
    <el-row>
      <el-col :span="8">
        <div class="sz-box">
          <div class="t-s">入场车辆</div>
          <number-scroll
            :num="pastCarCount.entered"
            :max-col="
              pastCarCount.entered && pastCarCount.entered.length > 2
                ? pastCarCount.entered.length
                : 2
            "
          /></div></el-col>
      <el-col
        :span="8"
      ><div class="sz-box">
        <div class="t-s">出场车辆</div>
        <number-scroll
          :num="pastCarCount.exited"
          :max-col="
            pastCarCount.exited && pastCarCount.exited.length > 2
              ? pastCarCount.exited.length
              : 2
          "
        /></div></el-col>
      <el-col
        :span="8"
      ><div class="sz-box">
        <div class="t-s">未离场车辆</div>
        <number-scroll
          :num="pastCarCount.totalStaying"
          :max-col="
            pastCarCount.totalStaying && pastCarCount.totalStaying.length > 2
              ? pastCarCount.totalStaying.length
              : 2
          "
        /></div></el-col>
    </el-row>
    <div class="box-item">
      <template v-if="carData && carData.length > 0">
        <line-chart
          id="car"
          :height="222"
          :margin-top="0"
          :val-options="carData"
          title=""
          y-axis-title="近七天车辆出入情况"
          serie-name="小时"
        />
      </template>
      <template v-else>
        <div class="stat-charts-context empty">暂无近7天车辆出入情况</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NumberScroll from '@/components/number-scroll'
import lineChart from '@/components/chart/lineChart.vue'
export default {
  components: { NumberScroll, lineChart },
  computed: {
    ...mapState('project', {
      pastCarCount: (state) => state.pastCarCount,
      sevenDaysCarCount: (state) => state.sevenDaysCarCount
    })
  },
  data() {
    return {
      carData: []
    }
  },
  watch: {
    sevenDaysCarCount: {
      handler(nval, oval) {
        let _name = ''
        nval.forEach((item) => {
          for (const prop in item) {
            if (prop === 'Name') {
              _name = item[prop]
              delete item.Name
              break
            }
          }
          this.carData.push({
            name: _name,
            data: item,
            isAllData: true
          })
        })
      },
      deep: true
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang='scss' scoped>
.sz-box {
  text-align: center;
}

.sz-box span {
  color: #fff;
  display: inline-block;
  font-family: "Digital-7";
  font-size: 24px;
  padding: 4px 0px;
  text-align: center;
  width: 32px;
  margin: 0 5px;
  border: 1px solid #00ffff;
  font-weight: bold;
  background: rgba(0, 255, 255, 0.2);
  border-radius: 4px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.5) 1%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0) 50%
  );
}
.box-item {
  margin-top: 20px;
  height: 222px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
}
.t-s {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
</style>
