<template>
  <div class="content-box-item">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="hjjc">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon1.png')"
              :fit="'cover'"
            />
            <div class="t-s">温度</div>
          </div>
          <p>{{ envData.temperature || 0 }}<span>°C</span></p>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="hjjc">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon2.png')"
              :fit="'cover'"
            />
            <div class="t-s">湿度</div>
          </div>
          <p>{{ envData.humidity || 0 }}<span>%</span></p>
        </div>
      </el-col>
      <el-col :span="12" class="mt-20">
        <div class="hjjc">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon3.png')"
              :fit="'cover'"
            />
            <div class="t-s">风速</div>
          </div>
          <p>{{ envData.windSpeed || 0 }}<span>m/s</span></p>
        </div>
      </el-col>
      <el-col :span="12" class="mt-20">
        <div class="hjjc warning-hjjc">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon4.png')"
              :fit="'cover'"
            />
            <div class="t-s">噪音</div>
          </div>
          <p>{{ envData.noise || 0 }}<span>dB</span></p>
        </div>
      </el-col>
      <el-col :span="12" class="mt-20">
        <div class="hjjc danger-box">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon5.png')"
              :fit="'cover'"
            />
            <div class="t-s">PM2.5</div>
          </div>
          <p>{{ envData.pM25 || 0 }}<span>ug/m³</span></p>
        </div>
      </el-col>
      <el-col :span="12" class="mt-20">
        <div class="hjjc">
          <div class="left">
            <el-image
              class="image"
              :src="require('../../../assets/image/hj-icon6.png')"
              :fit="'cover'"
            />
            <div class="t-s">颗粒物</div>
          </div>
          <p>{{ envData.tsp || 0 }}<span>ug/m³</span></p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapState('project', {
      envAllData: (state) => state.envAllData
    })
  },
  data() {
    return {
      timer: null,
      envData: {},
      current: 0,
      total: 0
    }
  },
  watch: {
    envAllData: {
      handler(nval, oval) {
        if (nval.length === 0) return
        this.total = nval.length - 1
        this.envData = nval[0]
        this.timer = setInterval(() => {
          if (this.current < this.total) {
            this.current += 1
            this.envData = nval[this.current]
          } else {
            this.current = 0
            this.envData = nval[this.current]
          }
        }, 10000)
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang='scss' scoped>
.hjjc {
  height: 62px;
  box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
  background: rgba(0, 255, 255, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.3);
  border-radius: 4px;
  text-align: center;
  position: relative;
  padding: 15px 10px 10px 50px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2) 1%,
    rgba(0, 255, 255, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  );
}

.hjjc .left {
  position: absolute;
  top: 5px;
  left: 20px;
  text-align: center;
}

.hjjc .image {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
}

.hjjc p {
  margin: 0;
  color: #00ffff;
  font-size: 24px;
}

.hjjc p span {
  font-size: 16px;
  color: #aaa;
  display: block;
  margin-top: 5px;
}

.t-s {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #fff;
  padding: 0;
}
.warning-hjjc {
  box-shadow: 0px 0px 15px rgba(250, 212, 0, 0.5) inset;
  background: rgba(250, 212, 0, 0.1);
  border: 1px solid rgba(250, 212, 0, 0.7);
  border-radius: 4px;
  text-align: center;
  position: relative;
  padding: 15px 10px 10px 50px;
  background-image: linear-gradient(
    rgba(250, 212, 0, 0.2) 1%,
    rgba(250, 212, 0, 0.05) 60%,
    rgba(250, 212, 0, 0) 60%
  );
}

.warning-hjjc p {
  color: #fad400;
}

.danger-box {
  box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.5) inset;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.7);
  border-radius: 4px;
  text-align: center;
  position: relative;
  padding: 15px 10px 10px 50px;
  background-image: linear-gradient(
    rgba(255, 0, 0, 0.3) 1%,
    rgba(255, 0, 0, 0.1) 60%,
    rgba(255, 0, 0, 0) 60%
  );
}

.danger-box p {
  color: #ff0000;
}
</style>
