<template>
  <div class="time">
    <span>{{ nowTime }}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      nowTime: '00:00:00'
    }
  },
  mounted() {
    this.currentTime()
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.getDate) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除时间定时器
    }
  },
  methods: {
    currentTime() {
      this.timer = setInterval(this.getDate, 500)
    },
    getDate() {
      const hh =
        new Date().getHours() < 10
          ? '0' + new Date().getHours()
          : new Date().getHours()
      const mm =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      this.nowTime = hh + ':' + mm + ':' + ss
    }
  }
}
</script>
