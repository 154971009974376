<template>
  <div>
    <el-carousel
      :height="'330px'"
      :indicator-position="'none'"
      :arrow="'hover'"
      :autoplay="true"
      :interval="30000"
      :loop="true"
      @change="carouselChange"
    >
      <el-carousel-item>
        <card-title @click="openPage('inspection')" is-more>质量监测</card-title>
        <div v-if="!carouselShow" class="content-box-item">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="qiteConfig1" class="bj" />
                <div class="font14 c-fff">整改率</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="qiteConfig2" class="bj" />
                <div class="font14 c-fff">待整改</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="qiteConfig3" class="bj" />
                <div class="font14 c-fff">待验证</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="qiteConfig4" class="bj" />
                <div class="font14 c-fff">已整改</div>
              </div></el-col>
          </el-row>
          <div class="box-item">
            <template v-if="qiteChartData && qiteChartData.length > 0">
              <line-chart
                id="question"
                :height="159"
                :margin-top="0"
                :val-options="qiteChartData"
                title=""
                y-axis-title="问题趋势"
                serie-name="小时"
              />
            </template>
            <template v-else>
              <div class="stat-charts-context empty  mt-50">暂无质量问题趋势</div>
            </template>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <card-title @click="openPage('inspection')" is-more>安全监测</card-title>
        <div v-if="carouselShow" class="content-box-item">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="sefaConfig1" class="bj" />
                <div class="font14 c-fff">整改率</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="sefaConfig2" class="bj" />
                <div class="font14 c-fff">待整改</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="sefaConfig3" class="bj" />
                <div class="font14 c-fff">待验证</div>
              </div></el-col>
            <el-col :span="6">
              <div class="zg-box">
                <dv-digital-flop :config="sefaConfig4" class="bj" />
                <div class="font14 c-fff">已整改</div>
              </div></el-col>
          </el-row>
          <div class="box-item">
            <template v-if="safeChartData && safeChartData.length > 0">
              <line-chart
                id="question"
                :height="159"
                :margin-top="0"
                :val-options="safeChartData"
                title=""
                y-axis-title="问题趋势"
                serie-name="小时"
              />
            </template>
            <template v-else>
              <div class="stat-charts-context empty mt-50">暂无安全问题趋势</div>
            </template>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import lineChart from '@/components/chart/lineChart.vue'
import cardTitle from "@/components/card-title";
export default {
  components: {
    lineChart,
    cardTitle
  },
  computed: {
    ...mapState('project', {
      qiteData: (state) => state.qiteData,
      sefaData: (state) => state.sefaData,
      qiteLineData: (state) => state.qiteLineData,
      sefaLineData: (state) => state.sefaLineData
    })
  },
  data() {
    return {
      qiteChartData: [], // 质量趋势图数据
      safeChartData: [], // 安全趋势图数据
      carouselShow: true,
      // 质量
      qiteConfig1: {
        number: [0],
        content: '{nt}%',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      qiteConfig2: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      qiteConfig3: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      qiteConfig4: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      // 安全
      sefaConfig1: {
        number: [0],
        content: '{nt}%',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      sefaConfig2: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      sefaConfig3: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      },
      sefaConfig4: {
        number: [0],
        content: '',
        style: {
          fontSize: 30,
          fill: '#fff'
        }
      }
    }
  },
  watch: {
    // 质量
    qiteData: {
      handler(nval, oval) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.carouselShow = false
            setTimeout(() => {
              this.qiteConfig1.number = [nval.checkedRate]
              this.qiteConfig1 = { ...this.qiteConfig1 }
              this.qiteConfig2.number = [nval.unRepaired]
              this.qiteConfig2 = { ...this.qiteConfig2 }
              this.qiteConfig3.number = [nval.unVerified]
              this.qiteConfig3 = { ...this.qiteConfig3 }
              this.qiteConfig4.number = [nval.checked]
              this.qiteConfig4 = { ...this.qiteConfig4 }
            }, 100)
          }, 100)
        })
      },
      deep: true
    },
    // 安全
    sefaData: {
      handler(nval, oval) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.carouselShow = false
            setTimeout(() => {
              this.sefaConfig1.number = [nval.checkedRate]
              this.sefaConfig1 = { ...this.sefaConfig1 }
              this.sefaConfig2.number = [nval.unRepaired]
              this.sefaConfig2 = { ...this.sefaConfig2 }
              this.sefaConfig3.number = [nval.unVerified]
              this.sefaConfig3 = { ...this.sefaConfig3 }
              this.sefaConfig4.number = [nval.checked]
              this.sefaConfig4 = { ...this.sefaConfig4 }
            }, 100)
          }, 100)
        })
      },
      deep: true
    },
    // 质量趋势图
    qiteLineData: {
      handler(nval, oval) {
        // console.log("质量", nval);
        this.qiteChartData.push(
          {
            name: '已整改',
            data: nval.checked,
            isAllData: false
          },
          {
            name: '未完成',
            data: nval.unChecked,
            isAllData: false
          },
          {
            name: '逾期完成',
            data: nval.overdueAndChecked,
            isAllData: false
          },
          {
            name: '已提交',
            data: nval.subbmitted,
            isAllData: false
          }
        )
      },
      deep: true
    },
    // 安全趋势图
    sefaLineData: {
      handler(nval, oval) {
        // console.log("安全", nval);
        this.safeChartData.push(
          {
            name: '已整改',
            data: nval.checked,
            isAllData: false
          },
          {
            name: '未完成',
            data: nval.unChecked,
            isAllData: false
          },
          {
            name: '逾期完成',
            data: nval.overdueAndChecked,
            isAllData: false
          },
          {
            name: '已提交',
            data: nval.subbmitted,
            isAllData: false
          }
        )
      },
      deep: true
    }
  },
  methods: {
    /**
     * 设备切换事件
     */
    carouselChange(index) {
      this.carouselShow = !this.carouselShow
      // 质量
      this.qiteConfig1.number = [0]
      this.qiteConfig1 = { ...this.qiteConfig1 }
      this.qiteConfig2.number = [0]
      this.qiteConfig2 = { ...this.qiteConfig2 }
      this.qiteConfig3.number = [0]
      this.qiteConfig3 = { ...this.qiteConfig3 }
      this.qiteConfig4.number = [0]
      this.qiteConfig4 = { ...this.qiteConfig4 }
      // 安全
      this.sefaConfig1.number = [0]
      this.sefaConfig1 = { ...this.sefaConfig1 }
      this.sefaConfig2.number = [0]
      this.sefaConfig2 = { ...this.sefaConfig2 }
      this.sefaConfig3.number = [0]
      this.sefaConfig3 = { ...this.sefaConfig3 }
      this.sefaConfig4.number = [0]
      this.sefaConfig4 = { ...this.sefaConfig4 }
      this.$nextTick(() => {
        setTimeout(() => {
          // 质量
          const qiteDataItem = this.qiteData
          this.qiteConfig1.number = [qiteDataItem.checkedRate]
          this.qiteConfig1 = { ...this.qiteConfig1 }
          this.qiteConfig2.number = [qiteDataItem.unRepaired]
          this.qiteConfig2 = { ...this.qiteConfig2 }
          this.qiteConfig3.number = [qiteDataItem.unVerified]
          this.qiteConfig3 = { ...this.qiteConfig3 }
          this.qiteConfig4.number = [qiteDataItem.checked]
          this.qiteConfig4 = { ...this.qiteConfig4 }
          // 安全
          const sefaDataItem = this.sefaData
          this.sefaConfig1.number = [sefaDataItem.checkedRate]
          this.sefaConfig1 = { ...this.sefaConfig1 }
          this.sefaConfig2.number = [sefaDataItem.unRepaired]
          this.sefaConfig2 = { ...this.sefaConfig2 }
          this.sefaConfig3.number = [sefaDataItem.unVerified]
          this.sefaConfig3 = { ...this.sefaConfig3 }
          this.sefaConfig4.number = [sefaDataItem.checked]
          this.sefaConfig4 = { ...this.sefaConfig4 }
        }, 100)
      })
    },
    /**
     * 跳转页面
     */
    openPage(path) {
      this.$router.push(`/${path}`)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow {
  //  background: rgba(153,153,153, 0.2)  !important;
  background: rgba(0, 255, 255, 0.4) !important;
}
.zg-box {
  border-radius: 50%;
  border: 3px solid #00ffff;
  box-shadow: 0px 0px 15px rgba(0, 255, 255, 0.5) inset;
  background: rgba(0, 255, 255, 0.1);
  width: 84px;
  height: 84px;
  text-align: center;
  margin: 0 auto;
  font-family: "Digital-7";
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2) 1%,
    rgba(0, 255, 255, 0.05) 60%,
    rgba(0, 255, 255, 0) 60%
  );
}
.bj {
  height: 45px;
  margin-top: 10px;
}
.font14 {
  font-size: 14px;
}
.box-item {
  margin-top: 20px;
  height: 159px;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
}
</style>
