<template>
  <el-row class="animated bounceIn">
    <div class="container-fluid">
      <div class="project-head f f-a-c">
        <el-col :span="6">
          <el-row>
            <el-col :span="24">
              <div class="fh-link f f-a-c">
                <div class="f f-a-c">
                  <el-image
                    class="fh-image c-p"
                    :src="require('../../../assets/image/fh-icon.png')"
                    :fit="'contain'"
                    @click.self="back"
                  />
                </div>
                <div class="fh-text text-ellipsis c-p" @click.self="back">
                  {{ ownerInfo.shortName || ownerInfo.name }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" class="f f-j-c f-a-c">
          <h2 class="top-conten-title t-a-c text-ellipsis">
            {{ projectInfo.name }}
          </h2>
        </el-col>
        <el-col :span="6">
          <template v-if="Object.keys(weather).length > 0">
            <el-row class="f f-a-c">
              <el-col
                :span="7"
              ><div class="time f f-a-c">
                <timer /></div></el-col>
              <el-col :span="3">
                <div class="f f-j-c f-a-c">
                  <el-image
                    class="tq-image"
                    :src="getWeatherIcon(weather.now.cond.code)"
                    :fit="'contain'"
                  />
                </div>
              </el-col>
              <el-col
                :span="8"
              ><div class="user f f-c f-j-c p-l-10">
                <p>{{ weather.now.cond.txt }} {{ weather.now.tmp }}℃</p>
                <p>{{ weather.dateNow }} {{ weather.week }}</p>
              </div></el-col>
              <el-col
                :span="6"
              ><div class="f f-a-c">
                <el-image
                  class="head-ztb-right-r"
                  :src="require(`../../../assets/image/position.png`)"
                  :fit="'contain'"
                />
                <span class="user p-l-5">{{ weather.basic.city }}</span>
              </div></el-col>
            </el-row>
          </template>
        </el-col>
      </div>
    </div>
  </el-row>
</template>

<script>
import timer from '@/components/timer'
import { mapState } from 'vuex'
export default {
  name: '',
  components: { timer },
  computed: {
    ...mapState('owner', {
      ownerInfo: (state) => state.ownerInfo
    }),
    ...mapState('project', {
      projectInfo: (state) => state.projectInfo
    }),
    ...mapState('project', {
      weather: (state) => state.weather
    })
  },
  data() {
    return { }
  },
  watch: {},
  methods: {
    /**
     * 返回上一页
     */
    back() {
      // this.$router.go(-1);
      this.$router.push('/home')
    },
    getWeatherIcon(code) {
      return 'http://cdn.axlyun.com/images/weather/3d-64/' + code + '.png'
    }
  }
}
</script>

<style lang='scss' scoped>
.container-fluid {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  .project-head {
    width: calc(100vw - 20px);
    height: 110px;
    background-image: url("../../../assets/image/top-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    .fh-link {
      .fh-image {
        width: 30px !important;
      }
      .fh-text {
        margin-left: 8px;
        color: #fff;
        font-size: 23px;
        &:hover {
          // color: #209ae3;
        }
      }
    }
    .top-conten-title {
      width: 740px;
      height: 110px;
      line-height: 110px;
      font-size: 36px;
      color: #fff;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 40%,
        rgba(0, 255, 255, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
    .time {
      font-family: "Digital-7";
      font-size: 29px;
      color: #fff;
    }
    .rq {
      padding: 0 10px;
      font-size: 12px;
      color: #eee;
    }
    .tq-image {
      width: 48px;
      height: 48px;
    }
    .user {
      font-size: 14px;
      color: #eee;
    }
    .head-ztb-right-r {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
