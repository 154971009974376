<template>
  <div class="content-box-item">
    <div v-if="articleList && articleList.length > 0" class="list-box">
      <vue-seamless-scroll
        :data="articleList"
        :class-option="classOption"
        class="seamless-scroll"
      >
        <template v-for="(item, index) in articleList">
          <div :key="index" class="item t-s text-ellipsis">
            【{{ item.title }}】{{ item.typeName }}，{{ item.timeSpan }}
          </div>
        </template>
      </vue-seamless-scroll>
    </div>
    <div v-else class="not-have-warning f f-j-c f-a-c">暂无政策资讯信息!</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('project', {
      articleList: (state) => state.articleList
    })
  },
  data() {
    return {
      classOption: {
        step: 0.5, // 数值越大速度滚动越快。
        limitMoveNum: 4, // 开启无缝滚动的数据量。
        hoverStop: true // 是否启用鼠标 hover 控制。
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  height: 150px;
  overflow: hidden;
  .seamless-scroll {
    height: 100%;
  }
}
.item {
  text-align: left;
  line-height: 35px;
  margin-bottom: 15px;
  background: rgba(0, 255, 255, 0.2);
  padding: 0 10px;
  border-radius: 20px;
}
.t-s {
  font-size: 16px;
  font-family: "Digital-7";
  color: #fff;
}
.not-have-warning {
  height: 150px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 255, 255, 0.6);
}
</style>
