<template>
  <div :class="`card-title${isMore ? ' c-p' : ''}`" @click="onClick">
    <span></span>
    <div><slot /></div>
    <span><i v-if="isMore" class="el-icon-more"></i></span>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    isMore: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>