<template>
  <div class="content-box-item">
    <div class="box2">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="td-icon">
            <el-image
              class="c-p image"
              :src="latestPeople.mugshot"
              :fit="'cover'"
            >
              <div slot="error" class="image-slot">
                <el-image
                  class="c-p image"
                  :src="require('../../../assets/image/tx-icon.png')"
                  :fit="'cover'"
                />
              </div>
            </el-image>
            <p class="t-s">{{ latestPeople.name || "-" }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tdxx">
            <p class="mb-10 t-s text-ellipsis">
              班组<span :title="latestPeople.teamName || '-'">{{ latestPeople.teamName || "-" }}</span>
            </p>
            <p class="t-s text-ellipsis">
              工种<span>{{ latestPeople.workTypeName || "-" }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tdxx">
            <p class="mb-10 t-s">
              状态<span>{{ latestPeople.passType | handlePassType }}</span>
            </p>
            <p v-if="nowDataPersonnel" class="t-s">
              时间
              <span v-if="latestPeople.passType == 0">{{
                latestPeople.inTime | parseTime("{h}:{i}:{s}")
              }}</span>
              <span v-else>{{
                latestPeople.outTime | parseTime("{h}:{i}:{s}")
              }}</span>
            </p>
            <p v-else class="t-s t-m-s">
              时间
              <span>
                <div v-if="latestPeople.passType == 0">
                  {{ latestPeople.inTime | parseTime("{m}月{d}日") }}
                </div>
                <div v-else>
                  {{ latestPeople.outTime | parseTime("{m}月{d}日") }}
                </div>
                <div v-if="latestPeople.passType == 0">
                  {{ latestPeople.inTime | parseTime("{h}:{i}:{s}") }}
                </div>
                <div v-else>
                  {{ latestPeople.outTime | parseTime("{h}:{i}:{s}") }}
                </div>
              </span>
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="box2"
      :class="!nowDataVehicle || !nowDataPersonnel ? 'mt-6' : 'mt-10'"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="td-icon">
            <el-image
              class="c-p image"
              :src="require('../../../assets/image/hc-icon.png')"
              :fit="'contain'"
            />
            <p class="t-s">{{ latestVehicles.license || "-" }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tdxx">
            <p class="mb-10 t-s">
              车牌色<span>{{ latestVehicles.colorStr || "-" }}</span>
            </p>
            <p class="t-s text-ellipsis">
              闸机<span>{{ latestVehicles.name || "-" }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="tdxx">
            <p class="mb-10 t-s">
              状态<span>{{ latestVehicles.passType | handlePassType }}</span>
            </p>
            <p v-if="nowDataVehicle" class="t-s">
              时间
              <span v-if="latestVehicles.passType == 0">{{
                latestVehicles.inTime | parseTime("{h}:{i}:{s}")
              }}</span>
              <span v-else>{{
                latestVehicles.outTime | parseTime("{h}:{i}:{s}")
              }}</span>
            </p>
            <p v-else class="t-s t-m-s">
              时间
              <span>
                <div v-if="latestPeople.passType == 0">
                  {{ latestPeople.inTime | parseTime("{m}月{d}日") }}
                </div>
                <div v-else>
                  {{ latestPeople.outTime | parseTime("{m}月{d}日") }}
                </div>
                <div v-if="latestPeople.passType == 0">
                  {{ latestPeople.inTime | parseTime("{h}:{i}:{s}") }}
                </div>
                <div v-else>
                  {{ latestPeople.outTime | parseTime("{h}:{i}:{s}") }}
                </div>
              </span>
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="box2 t-s text-ellipsis"
      :class="!nowDataVehicle || !nowDataPersonnel ? 'mt-6' : 'mt-10'"
    >
      <span v-if="latestAlarm.name">【{{ latestAlarm.name }}】</span>
      <span v-if="latestAlarm.time">{{ latestAlarm.time }}，</span>
      <span v-if="latestAlarm.content"> {{ latestAlarm.content }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { parseTime } from '@/utils/index'
export default {
  name: '',
  components: {},
  computed: {
    ...mapState('project', {
      latestPeople: (state) => state.latestPeople,
      latestVehicles: (state) => state.latestVehicles,
      latestAlarm: (state) => state.latestAlarm
    })
  },
  filters: {
    handlePassType(value) {
      return value === 0 ? '入场' : '出场'
    }
  },
  data() {
    return {
      nowDataPersonnel: true,
      nowDataVehicle: true,
      nowData: parseTime(new Date().getTime(), '{y}-{m}-{d}')
    }
  },
  watch: {
    latestPeople(val) {
      this.removePersonnel()
    },
    latestVehicles(val) {
      this.removeVehicle()
    }
  },
  mounted() {
    this.removePersonnel()
    this.removeVehicle()
  },
  methods: {
    /**
     * 人员处理
     */
    removePersonnel() {
      this.nowDataPersonnel =
        parseTime(
          this.latestPeople.passType === 0
            ? this.latestPeople.inTime
            : this.latestPeople.outTime,
          '{y}-{m}-{d}'
        ) === this.nowData
    },
    /**
     * 人员处理
     */
    removeVehicle() {
      this.nowDataVehicle =
        parseTime(
          this.latestVehicles.passType === 0
            ? this.latestVehicles.inTime
            : this.latestVehicles.outTime,
          '{y}-{m}-{d}'
        ) === this.nowData
    }
  }
}
</script>

<style lang='scss' scoped>
.box2 {
  text-align: center;
  border: 1px solid rgba(0, 255, 255, 0.2);
  background: rgba(0, 255, 255, 0.05);
  padding: 15px 10px 15px 10px;
}
.td-icon {
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2),
    rgba(0, 255, 255, 0.1)
  );
  border-radius: 4px;
  padding: 5px;
}

.td-icon > .image {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.td-icon p {
  margin-bottom: 0;
}
.tdxx p {
  border-radius: 4px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.2),
    rgba(0, 255, 255, 0.1)
  );
  text-align: left;
  padding: 5px;
}

.tdxx span {
  margin: 0 5px;
  border-radius: 4px;
  font-size: 15px;
  color: #00ffff;
}
.t-s {
  font-family: "微软雅黑";
  font-size: 16px;
  color: #fff;
}
.t-m-s {
  display: flex;
  align-items: center;
  padding: 0px 5px !important;
}
</style>
