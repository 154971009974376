<template>
  <div class="center-top-centent">
    <!-- 顶部切换按钮 -->
    <div class="top-btn p-f f f-j-c f-a-c">
      <div
        :class="`top-btn-item mr-5 c-p ${nowShow === 0 ? 'select-color' : ''}`"
        @click="handleShow(0)"
      >
        形象工程
      </div>
      <div
        :class="`top-btn-item mr-5 c-p ${nowShow === 1 ? 'select-color' : ''}`"
        @click="handleShow(1)"
      >
        无人机航拍
      </div>
      <div
        :class="`top-btn-item mr-5 c-p ${nowShow === 2 ? 'select-color' : ''}`"
        @click="handleShow(2)"
      >
        云监工
      </div>
    </div>
    <!-- 形象工程 -->
    <image-engineering v-if="nowShow === 0" ref="engineering" />
    <!-- 无人机航拍 -->
    <aerial-photography v-if="nowShow === 1" />
    <!-- 云监工 -->
    <online-supervisor v-if="nowShow === 2" />
  </div>
</template>

<script>
import imageEngineering from './modular/image-engineering'
import aerialPhotography from './modular/aerial-photography'
import onlineSupervisor from './modular/online-supervisor'
export default {
  components: {
    imageEngineering,
    aerialPhotography,
    onlineSupervisor
  },
  data() {
    return {
      nowShow: 0
    }
  },
  computed: {},
  methods: {
    /**
     * 显示内容切换事件
     */
    handleShow(i) {
      if (this.nowShow === i) return
      // 处理形象工程数据不刷新问题
      if (i === 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs['engineering'].rendering = true
          }, 10)
        })
      }
      this.nowShow = i
    }
  }
}
</script>

<style lang="scss" scoped>
.select-color {
  color: rgba(0, 255, 255, 1) !important;
}
.center-top-centent {
  width: 100%;
  height: 530px;
  position: relative;
}
.top-btn {
  top: 0;
  left: 0;
  right: 0;
  padding-top: 10px;
  z-index: 10;
}
.top-btn-item {
  padding: 6px 12px;
  border: 1px solid rgba(0, 255, 255, 0.6);
  background: rgba(0, 255, 255, 0.2);
  border-radius: 4px;
  background-image: linear-gradient(
    rgba(0, 255, 255, 0.5) 1%,
    rgba(0, 255, 255, 0.2) 50%,
    rgba(0, 255, 255, 0) 50%
  );
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: rgba(0, 255, 255, 1) !important;
  }
}
</style>
